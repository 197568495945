import logo from "./logo.svg";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>The 21st Sensory LLC</h1>
        <p>Coming Soon...</p>
        <h3>Contact</h3>
        <p>contact@the21stsensory.com</p>
      </header>
    </div>
  );
}

export default App;
